import { find, map } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicantResource } from "shared-module/api-resources/applicant.resource";
import { ApplicationResource } from "shared-module/api-resources/application.resource";
import { LookupResource } from "shared-module/api-resources/lookup.resource";
import { SystemResource } from "shared-module/api-resources/system.resource";
import ProcessButton from "shared-module/components/button/process-button.component";
import { ShowThen } from "shared-module/components/show-then/show-then.component";
import { Utility } from "shared-module/helpers/utility";
import { ApplicationTypeNames, DocumentTypeCodes, GatewayNetworkError } from "shared-module/models/common.models";
import { GatewayOapApi } from "shared-module/models/gateway-oap-api.models";
import { ApiConfig } from "shared-module/services/api-config";
import { Card } from "../../../shared-module/components/card/card.component";
import { NavigationHelper } from "../../../shared-module/helpers/navigation-helper";
import { AcceptUserInvitationModal } from "../../extensions/accept-user-invitation-modal/accept-user-invitation-modal.component";
import { InviteUserModal } from "../../extensions/invite-user-modal/invite-user-modal.component";
import { ApplicationDashboardDocument } from "./applicant-dasboard-document/application-dasboard-document.components";

interface ApplicationDocuments {
  documentName: string,
  documentTypeName: string,
  documentTypeId: number,
  documentTypeCode: string,
  hasDocument: boolean,
  uploadedOn: string
}

interface Application {
  applicationTypeName: string;
  NbarApplicationRefId: string;
  applicationFileName: string;
  firstName: string,
  lastName: string,
  isSubmitted: boolean,
  applicationStatusName: string,
  remainingDays: number,
  documents: ApplicationDocuments[];
}

function ApplicantDashboard() {
  const [applicationDocuments, setApplicationDocuments] = useState<Application[]>([]);
  const navigate = useNavigate();
  const applicantService = new ApplicantResource(ApiConfig);
  const applicationService = new ApplicationResource(ApiConfig);
  const lookupService = new LookupResource(ApiConfig);
  const systemService = new SystemResource(ApiConfig);
  const [cookie, setCookie] = useState<string>("false");


  useEffect(() => {
    Utility.showLoadingOverlay();
    refresh()
      .finally(() => {
        Utility.hideLoadingOverlay();
      });
  }, []);

  function refresh(): Promise<any> {
    return Promise.all([
      applicantService.getApplicantApplications(),
      applicantService.getApplicantDocuments(),
      lookupService.getDocumentTypes(),
      systemService.getCookie(),
    ])
      .then((response) => {
        var apps = response[0].records || [];
        var docs = response[1].records || [];
        var docTypes = response[2].records || [];
        setCookie(response[3].record);

        var codType = find(docTypes, (item) => { return item.documentTypeCode == DocumentTypeCodes.COD });
        var afdType = find(docTypes, (item) => { return item.documentTypeCode == DocumentTypeCodes.AFD });
        
        var appDocs = map(apps, (item) => {
          return {
            applicationTypeName: ApplicationTypeNames.BDS,
            NbarApplicationRefId: item.applicationRefId,
            firstName: item.firstName,
            lastName: item.lastName,
            isSubmitted: item.isSubmitted,
            applicationFileName: item.applicationFileName,
            applicationStatusName: item.applicationStatusName,
            remainingDays: item.remainingDays,
            documents: (() => {
              var cod = getDocumentFileInfo(item.applicationId, docs, codType);
              var afd = getDocumentFileInfo(item.applicationId, docs, afdType);
              return [cod, afd];
            })()
          } as Application;
        });

        setApplicationDocuments(appDocs);
      });
  }

  function getDocumentFileInfo(
    applicationId: number,
    docs: GatewayOapApi.ApplicationDocumentModel[],
    fileType: GatewayOapApi.DocumentTypeModel): ApplicationDocuments {

    const file: GatewayOapApi.ApplicationDocumentModel = find(docs, (doc) => {
      return doc.applicationId == applicationId &&
        doc.documentTypeId == fileType.documentTypeId
    });

    return {
      documentName: (!!file) ? file.documentName : null,
      hasDocument: !!file,
      documentTypeName: fileType.documentTypeName,
      documentTypeId: fileType.documentTypeId,
      documentTypeCode: fileType.documentTypeCode,
      uploadedOn: (!!file) ? file.uploadedOn : null
    } as ApplicationDocuments;
  }

  const downloadApplication = (applicationRefId : string, applicationFileName: string) : Promise<boolean> => {
    return openApplicationforPrint(applicationRefId, applicationFileName)
      .then(() => {
        return true;
      })
      .catch(ex => {
        if (!(ex instanceof GatewayNetworkError)) {
            Utility.alert("Error Saving", ex);
        }
        return false;
    });
  }
  
  function openApplicationforPrint(applicationRefId: string, applicationFileName: string): Promise<any> {
    return applicationService.printSubmittedApplication(applicationRefId)
    .then((response: string) => {
      serveFile([response], applicationFileName);
      return true;
    });
  }

  function serveFile(blob: [string], fileName: string) {
    var file = new Blob(blob, { type: "application/pdf" });
    var fileURL = URL.createObjectURL(file);
    var fileLink = document.createElement("a");
    fileLink.target = "_blank";
    fileLink.href = fileURL;
    fileLink.download = fileName;
    fileLink.click();
  }

  function editApplication(applicationRefId: string) {
    NavigationHelper.gotoEditApplication(navigate, applicationRefId);
  }

  function removeApplication(NbarApplicationRefId: string) {   
    Utility.confirm("Confirm", "Are you sure to delete this application?", (yes) => {
      if (yes) {
        Utility.showLoadingOverlay();
        return applicationService.removeApplication(NbarApplicationRefId)
          .then((response) => {
            return refresh();
          })
          .finally(() => {
            Utility.hideLoadingOverlay();
          });
      }
      return Promise.resolve();
    });   
  }

  function hasSubmittedApplications() {
    return !!find(applicationDocuments, (item: Application) => {
      return item.isSubmitted
    });
  }

  return (
    <>
      <InviteUserModal></InviteUserModal>
      <AcceptUserInvitationModal id="acceptUserInvitation"></AcceptUserInvitationModal>
      <div className=" mt-4">
        <Card>
          <div className="bds-dashboard-section">
            <div className="section-heading">My Applications</div>
            <ShowThen when={!applicationDocuments.length}>
              <div className="text-center">No application found.</div>
            </ShowThen>
            <div className="list-group mt-3">
              {
                applicationDocuments.map((app, index) => (
                  <li key={index} className="list-group-item">
                    <div className="row mt-2">
                      <div className="col-12 col-md-3">
                        <label>Application Name:</label>
                      </div>
                      <div className="col-12 col-md-9 mt-2 mt-md-0 text-wrap">
                        <span className="me-3 end-with-breaking-space">
                          {app.applicationFileName}
                        </span>
                      </div>
                      <div className="col-6 col-md-3 mt-2">
                        <label>Status:</label></div>
                      <div className="col-6 col-md-3 mt-2">
                        {app.applicationStatusName}
                      </div>
                      <ShowThen when={!app.isSubmitted}>
                      <div className="col-6 col-md-3 mt-2">
                        <label>Days Remaining to Submit:</label></div>
                        <div className="col-6 col-md-3 mt-2">{app.remainingDays} day(s)</div>
                      </ShowThen>
                      <div className="col-6 col-md-3 mt-2">
                        <label>Type:</label></div>
                      <div className="col-6 col-md-3 mt-2">{app.applicationTypeName}</div>
                      <div className="col-12 my-2">
                        <ProcessButton type="button" cssClass="btn btn-sm btn-primary mt-2 me-0 me-md-2"
                          id={`${app.applicationTypeName}_${app.NbarApplicationRefId}`}
                          isProcessing
                          iconClass="bi bi-cloud-arrow-down-fill"
                          onClick = {() => downloadApplication(app.NbarApplicationRefId, app.applicationFileName)}
                          >
                            Download
                        </ProcessButton>
                        <ShowThen when={!app.isSubmitted}>
                          <button type="button" className="btn btn-sm btn-primary mt-2 me-0 me-md-2"
                            onClick={() => editApplication(app.NbarApplicationRefId)}>
                            Continue Editing
                          </button>
                          <button type="button" className="btn btn-sm btn-danger mt-2 me-0 me-md-2"
                            onClick={() => removeApplication(app.NbarApplicationRefId)}>
                            Remove Application
                          </button>
                        </ShowThen>
                      </div>
                    </div>
                  </li>
                ))
              }
            </div>
          </div>
        </Card>
      </div>

      <div className=" mt-4">
        <Card>
          <div className="bds-dashboard-section">
            <div className="section-heading">My Documents</div>
            <ShowThen when={!hasSubmittedApplications()}>
              <div className="text-center">No document found.</div>
            </ShowThen>
            {
              applicationDocuments.map((app, index) => (
                <div key={index}>
                  <ShowThen when={app.isSubmitted}>

                    <div className="section-sub-heading mt-3">{app.firstName} {app.lastName}</div>
                    <ul className="list-group mt-1">
                      {
                        app.documents.map((subitem, subIndex) => (
                          <li className="list-group-item">
                            <ApplicationDashboardDocument key={subIndex}
                              applicationRefId={app.NbarApplicationRefId}
                              isSubmitted={app.isSubmitted}
                              documentName={subitem.documentName}
                              documentTypeName={subitem.documentTypeName}
                              lastUploadedOn={subitem.uploadedOn}
                              hasDocument={subitem.hasDocument}
                              documentTypeCode={subitem.documentTypeCode}
                              onChange={() => { refresh(); }}
                            ></ApplicationDashboardDocument>
                          </li>
                        ))
                      }
                    </ul>
                  </ShowThen>
                </div>
              ))
            }
          </div>
        </Card>
      </div>

      <ShowThen when={false}>
        <div className=" mt-4">
          <Card>
            <div className="bds-dashboard-section">
              <div className="section-heading">My Contributed Applications</div>
              <div className="list-group mt-3">
                <li className="list-group-item">
                  <div className="row mt-2">
                    <div className="col-12 col-md-3">
                      <label>Application Name:</label>
                    </div>
                    <div className="col-12 col-md-9 mt-2 mt-md-0 text-wrap">
                      <span className="me-3 end-with-breaking-space">LastNameFirstName_BDSApp_yyyymmdd</span>
                      <a href="#" className="text-decoration-none">(download)</a>
                    </div>
                    <div className="col-6 col-md-3 mt-2">
                      <label>Status:</label>
                    </div>
                    <div className="col-6 col-md-3 mt-2">In Progress</div>
                    <div className="col-6 col-md-3 mt-2">
                      <label>Remaining Days:</label>
                    </div>
                    <div className="col-6 col-md-3 mt-2">170 days</div>
                    <div className="col-12 my-2">
                      <button type="button" className="btn btn-sm btn-warning mt-2"
                        data-bs-toggle="modal" data-bs-target="#acceptUserInvitation">Accept Invitation</button>
                    </div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="row mt-2">
                    <div className="col-12 col-md-3">
                      <label>Application Name:</label>
                    </div>
                    <div className="col-12 col-md-9 mt-2 mt-md-0 text-wrap">
                      <span className="me-3 end-with-breaking-space">LastNameFirstName_BDSApp_yyyymmdd</span>
                      <a href="#" className="text-decoration-none">(download)</a>
                    </div>
                    <div className="col-6 col-md-3 mt-2">
                      <label>Status:</label>
                    </div>
                    <div className="col-6 col-md-3 mt-2">In Progress</div>
                    <div className="col-6 col-md-3 mt-2">
                      <label>Remaining Days:</label>
                    </div>
                    <div className="col-6 col-md-3 mt-2">170 days</div>
                    <div className="col-12 my-2">
                      <button type="button" className="btn btn-sm btn-primary mt-2">Continue Editing</button>
                      <button type="button" className="btn btn-sm btn-danger mt-2 ms-0 ms-md-2">Leave Application</button>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </Card>
        </div>
      </ShowThen>

      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <button type="button"
            onClick={() => NavigationHelper.gotoHome(navigate)}
            className="btn bds-btn-primary">Back to Home</button>
        </div>
        <ShowThen when={cookie==="true"}>
          <div className="col-12 col-md-6 text-start text-md-end">
            <button type="button"
                onClick={() => NavigationHelper.gotoNbarHome(navigate)}
                className="btn bds-btn-primary">Proceed to NBAR</button>
          </div>
        </ShowThen>
      </div>
    </>
  );
}

export default observer(ApplicantDashboard);