import { RouteObject, redirect } from "react-router-dom";
import { NavigationHelper } from "shared-module/helpers/navigation-helper";
import { SessionService } from "shared-module/services/session.service";
import { ErrorBoundary } from "../shared-module/components/error-boundary/error-boundary";
import App from "./components/_app/app.component";
import { Layout } from "./components/_layout/layout.component";
import ApplicantDashboard from "./components/applicant-dashboard/applicant-dashboard.component";
import ApplicantDemographics from "./components/applicant-demographics/applicant-demographics.component";
import ApplicantDocumentUpload from "./components/applicant-document-upload/applicant-document-upload";
import ApplicantInformation from "./components/applicant-information/applicant-information.component";
import ApplicantSignature from "./components/applicant-signature/applicant-signature.component";
import ApplicationInformation from "./components/application-information/application-information.component";
import ApplicationSessionExpired from "./components/application-session-expired/application-session-expired.component";
import ApplicationSubmitted from "./components/application-submitted/applicationsubmitted.component";
import ApplicantionSummary from "./components/application-summary/application-summary.component";
import HomePage from "./components/home-page/home-page.component";
import LegalRepresentative from "./components/legal-representative/legal-representative.component";
import { OidcAuthorize } from "./components/oidc/oidc-authorize/oidc-authorize";
import { OidcUnauthorize } from "./components/oidc/oidc-unauthorize/oidc-unauthorize";
import { SignInOidcLanding } from "./components/oidc/signin-landing-oidc/signin-landing-oidc";
import { SignOutOidcLanding } from "./components/oidc/signout-landing-oidc/signout-landing-oidc";
import PageNotFound from "./components/page-not-found/page-not-found.component";
import StartApplication from "./components/start-application/start-application.component";


function routes() {

  async function authenticationGuard() {
    var authenticated = await SessionService.isAuthenticated();
    if (!authenticated) {
      return redirect(NavigationHelper.routes.home);
    }
    return null;
  }

  async function permissionGuard(permissions: string[]) {
    var HasPermissions = await SessionService.hasPermission(permissions);
    if (!HasPermissions) {
      return redirect(NavigationHelper.routes.home);
    }
    return null;
  }

  return [
    {
      path: "/"
    },
    {
      path: "",
      element: <App></App>,
      errorElement: <ErrorBoundary></ErrorBoundary>,
      children: [
        {
          path: "",
          element: <Layout></Layout>,
          children: [
            { index: true, element: <HomePage /> },
              
            { path: "home", element: <HomePage /> },
            { 
              path: "dashboard", 
              element: <ApplicantDashboard />, 
              loader: async () => await authenticationGuard() 
            },
            { path: "start-application", element: <StartApplication /> },
            { path: "start-application/:id", element: <StartApplication /> },
            { path: "application-information/:id", element: <ApplicationInformation /> },
            { path: "applicant-information/:id", element: <ApplicantInformation /> },
            { path: "applicant-demographics/:id", element: <ApplicantDemographics /> },
            { path: "legal-representative/:id", element: <LegalRepresentative /> },
            { path: "application-summary/:id", element: <ApplicantionSummary /> },            
            { path: "upload-documents/:id", element: <ApplicantDocumentUpload /> },
            { path: "signature/:id", element: <ApplicantSignature /> },
            { path: "application-submitted/:id", element: <ApplicationSubmitted /> },
            { path: "session-expired", element: <ApplicationSessionExpired /> },
          ]
        },
        { path: "authorize", element: <OidcAuthorize /> },
        { path: "unauthorize", element: <OidcUnauthorize /> },
        { path: "signin-landing-oidc", element: <SignInOidcLanding /> },
        { path: "signout-landing-oidc", element: <SignOutOidcLanding /> },
        
        {
          path: "*",
          element: <Layout></Layout>,
          children: [
            { path: "*", element: <PageNotFound /> }
          ]
        }
      ]
    },
  ] as RouteObject[];
};

export default routes();
