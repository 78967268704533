import { ApplicationProgressBar } from "app-module/extensions/application-progress-bar/application-progress-bar.component";
import { PageTitleSetter } from "app-module/extensions/page-title-setter/page-title-setter.component";
import { observer } from "mobx-react-lite";
import { ErrorContainer } from "shared-module/components/error-container/error-container.component";
import { ShowThen } from "shared-module/components/show-then/show-then.component";
import { DocumentUploadStore, IDocumentUpload } from "./applicant-document-upload.store";
import { useEffect, useState } from "react";
import { DocumentUploadValidationSchema } from "./applicant-document-upload.validation";
import { useFormValidation } from "shared-module/hooks/use-form-validation/use-form-validation.hook";
import { Card } from "shared-module/components/card/card.component";
import { NavigationHelper } from "shared-module/helpers/navigation-helper";
import { useNavigate, useParams } from "react-router-dom";
import { Utility } from "shared-module/helpers/utility";
import { ApplicationResource } from "shared-module/api-resources/application.resource";
import { ApiConfig } from "shared-module/services/api-config";
import { AppSettings } from '../../../app-setting'
import { IsAuthenticated } from "shared-module/components/is-authenticated/is-authenticated";
import { LookupResource } from "shared-module/api-resources/lookup.resource";


function ApplicantDocumentUpload() {
  const navigate = useNavigate();
  const { id: applicationRefId } = useParams();
  const applicationService = new ApplicationResource(ApiConfig);
  const store = DocumentUploadStore;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [savedState, setSavedState] = useState<IDocumentUpload>({} as IDocumentUpload);
  const { errors, touched, setFieldsTouched } = useFormValidation(DocumentUploadValidationSchema, store);
  const lookupService = new LookupResource(ApiConfig);
  const [isCodLoading, setIsCodLoading] = useState<boolean>(false);
  const [isAfdLoading, setIsAfdLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!hasApplicationRefId()) {
      NavigationHelper.gotoHome(navigate);
    }
    setIsLoading(true);
    Utility.showLoadingOverlay();
    init();
  }, []);

  function init(): Promise<any> {
    store.reset();
    return Promise.all([
      lookupService.getDocumentTypes(),
      applicationService.getStagedApplicationDocuments(applicationRefId)
    ])
      .then((response) => {
        if (response[0]?.records?.length > 0) {
          store.setDocumentTypes(response[0].records);
        }
        if (response[1]?.records?.length > 0) {
          store.refresh(response[1].records)
        };
        return true;
      })
      .catch((ex) => { NavigationHelper.gotoHome(navigate); })
      .finally(() => {
        setIsLoading(false);
        Utility.hideLoadingOverlay();
      });
  }

  function hasApplicationRefId() {
    return !!applicationRefId;
  }

  async function handleConfirmationOfDiagnosisValueChange(e) {
    setIsLoading(true);
    setIsCodLoading(true);
    store.setConfirmationOfDiagnosisFile(e.target.files[0]);

    const isCODValid = await DocumentUploadValidationSchema.validate(store).catch(error => {
      error = {...error.message}
      console.log('ERROR: ', error);
      return error.key !== 'confirmationOfDiagnosisFile';
    });
    
    if(!isCODValid) {
      setIsLoading(false);
      setIsCodLoading(false);
      throw new Error('the replacement file was not valid');
    } else {
      applicationService.saveStagedApplicationDocuments(applicationRefId, e.target.files[0], store.getConfirmationOfDiagnosisTypeId())
        .then(() => {
          store.setConfirmationOfDiagnosisFileName(e.target.files[0].name);
        }).catch((error) => {
          Utility.alert("Unexpected Error", "Unable to upload the document");
          throw error;
        }).finally(() => {
          setIsLoading(false);
          setIsCodLoading(false);
        })
    }
  }

  async function handleAuthorizationForDisclosureValueChange(e) {
    setIsLoading(true);
    setIsAfdLoading(true);
    store.setAuthorizationForDisclosureFile(e.target.files[0]);

    const isAFDValid = await DocumentUploadValidationSchema.validate(store).catch(error => {
      error = {...error.message}
      console.log('ERROR: ', error);
      return error.key !== 'authorizationForDisclosureFile';
    });
    
    if(!isAFDValid) {
      setIsLoading(false);
      setIsAfdLoading(false);
      throw new Error('the replacement file was not valid');
    } else {
      applicationService.saveStagedApplicationDocuments(applicationRefId, e.target.files[0], store.getAuthorizationForDisclosureTypeId())
        .then(() => {
          store.setAuthorizationForDisclosureFileName(e.target.files[0].name);
        }).catch((error) => {
          Utility.alert("Unexpected Error", "Unable to upload the document");
          throw error;
        }).finally(() => {
          setIsLoading(false);
          setIsAfdLoading(false);
        })
    }
  }

  // async function handleAuthorizationForDisclosureValueChange(e) {
  //   setIsLoading(true);
  //   setIsAfdLoading(true);
  //   store.setAuthorizationForDisclosureFile(e.target.files[0]);
  //   DocumentUploadValidationSchema.validate(store).then(response => {
  //     applicationService.saveStagedApplicationDocuments(applicationRefId, e.target.files[0], store.getAuthorizationForDisclosureTypeId())
  //       .then(() => {
  //         store.setAuthorizationForDisclosureFileName(e.target.files[0].name);
  //       }).catch((error) => {
  //         Utility.alert("Unexpected Error", "Unable to upload the document");
  //         throw error;
  //       })
  //   }).finally(() => {
  //       setIsLoading(false);
  //       setIsAfdLoading(false);
  //   })
  // }

  async function handleRemoveAuthorizationForDisclosureFile() {
    if (!isLoading) {
      store.setAuthorizationForDisclosureFile(null);
      store.setAuthorizationForDisclosureFileName(null);
      applicationService.removeStagedApplicationDocuments(applicationRefId, store.getAuthorizationForDisclosureTypeId());
    }
  }

  async function handleRemoveConfirmationOfDiagnosisFile() {
    if (!isLoading) {
      store.setConfirmationOfDiagnosisFile(null);
      store.setConfirmationOfDiagnosisFileName(null);
      applicationService.removeStagedApplicationDocuments(applicationRefId, store.getConfirmationOfDiagnosisTypeId());
    }
  }

  function handleGoToNextStep() {
    NavigationHelper.gotoSignature(navigate, applicationRefId);
  }

  function handleConfirmationOfDiagnosisPreview() {
    Utility.preview("Confirmation Of Diagnosis", 'confirmation-of-diagnosis');
  }

  function handleAuthorizationForDisclosurePreview() {
    Utility.preview("Authorization For Disclosure", 'authorization-for-disclosure');
  }


  return (
    <>
      <PageTitleSetter step={7} totalSteps={8}></PageTitleSetter>

      <div className="mt-4">
        <ApplicationProgressBar step={7} totalSteps={8}></ApplicationProgressBar>
      </div>

      <ShowThen when={errors.length > 0}>
        <ErrorContainer data={store} saved={savedState} touched={touched} errors={errors}></ErrorContainer>
      </ShowThen>

      <div className="mt-4 col-md-12">
        <Card>
          <div className="bds-section-heading">
            Upload Documentation (Optional)
            <hr></hr>
          </div>
          <form>
            <div className="row mt-3 d-flex flex-column align-items-center">
              <div className="row col-md-10 mb-5">
                <div className="col-md-7 mb-3">
                  <div><strong className="me-2">Confirmation of Diagnosis</strong><a onClick={handleConfirmationOfDiagnosisPreview} id="confirmation-of-diagnosis-preview" className="bds-anchor">{`(What is this?)`}</a></div>
                  <div>{`Confirmation of Diagnosis: A physician's confirmation of the individual's developmental disability / intellectual disability (DD/ID).`}</div>
                </div>
                <div className="col-md-5">
                  <div className="bds-document-upload-wrapper">
                    <label aria-label="confirmationOfDiagnosis" htmlFor="confirmationOfDiagnosis" className="btn bds-btn-primary me-2">
                      <ShowThen when={isCodLoading}>
                        <div className="spinner-border me-1" role="status"></div>
                        <span className="">Uploading...</span>
                      </ShowThen>
                      <ShowThen when={!isCodLoading}>
                        <i className="bi bi-cloud-arrow-up-fill me-2"></i>
                        {store.confirmationOfDiagnosisFileName ? 'Replace File' : 'Upload File'}
                      </ShowThen>
                    </label>
                    <span>{`Max file size ${AppSettings.maxUploadFileSize / 1000000}MB.`}</span>
                    <input type="file" name="confirmationOfDiagnosisFile" id="confirmationOfDiagnosis"
                      disabled={isLoading}
                      key={store.confirmationOfDiagnosisFile?.name || " "}
                      onChange={(e) => {
                        setFieldsTouched("confirmationOfDiagnosisFile");
                        handleConfirmationOfDiagnosisValueChange(e);
                      }}
                      className="d-none"
                      accept={AppSettings.validUploadFileFormats.toString()}>
                    </input>
                  </div>
                  <ShowThen when={store.confirmationOfDiagnosisFileName}>
                    <div>
                      <span className="me-1">File Uploaded:</span>
                      <label id="confirmation-of-diagnosis-show" htmlFor="confirmationOfDiagnosisShow"><a className="bds-anchor">{store.confirmationOfDiagnosisFileName}</a></label>
                      <input type="file" name="confirmationOfDiagnosisShow" id="confirmationOfDiagnosisShow"
                        disabled={isLoading}
                        key={store.confirmationOfDiagnosisFileName || " "}
                        onChange={(e) => {
                          setFieldsTouched("confirmationOfDiagnosisFile");
                          handleConfirmationOfDiagnosisValueChange(e);
                        }}
                        className="d-none"
                        accept={AppSettings.validUploadFileFormats.toString()}>
                      </input>
                      <i className="bi bi-trash ms-1" onClick={handleRemoveConfirmationOfDiagnosisFile}></i></div>
                  </ShowThen>
                </div>
              </div>

              <div className="row col-md-10 mb-5">
                <div className="col-md-7 mb-3">
                  <div><strong className="me-2">Authorization for Disclosure</strong><a onClick={handleAuthorizationForDisclosurePreview} id="authorization-for-disclosure-preview" className="bds-anchor">{`(What is this?)`}</a></div>
                  <div>{`Authorization for Disclosure: For you to authorize the disclosure of your personal information, which may include health information, to persons or organizations outside of the Division of Family Resources (DFR). Your privacy is protected by state and federal privacy laws. As such, we need your explicit permission to make the requested disclosure.`}</div>
                </div>
                <div className="col-md-5">
                  <div className="bds-document-upload-wrapper">
                    <label aria-label="authorizationForDisclosure" htmlFor="authorizationForDisclosure" className="btn bds-btn-primary me-2">
                      <ShowThen when={isAfdLoading}>
                        <div className="spinner-border me-1" role="status"></div>
                        <span className="">Uploading...</span>
                      </ShowThen>
                      <ShowThen when={!isAfdLoading}>
                        <i className="bi bi-cloud-arrow-up-fill me-2"></i>
                        {store.authorizationForDisclosureFileName ? 'Replace File' : 'Upload File'}
                      </ShowThen>

                    </label>
                    <span>{`Max file size ${AppSettings.maxUploadFileSize / 1000000}MB.`}</span>
                    <input type="file" name="authorizationForDisclosureFile" id="authorizationForDisclosure"
                      disabled={isLoading}
                      key={store.authorizationForDisclosureFileName || " "}
                      onChange={(e) => {
                        setFieldsTouched('authorizationForDisclosureFile');
                        handleAuthorizationForDisclosureValueChange(e);
                      }}
                      className="d-none"
                      accept={AppSettings.validUploadFileFormats.toString()}>
                    </input>
                  </div>
                  <ShowThen when={store.authorizationForDisclosureFileName}>
                    <div>
                      <span className="me-1">File Uploaded:</span>
                      <label id="authorization-for-disclosure-show" htmlFor="authorizationForDisclosureShow"><a className="bds-anchor">{store.authorizationForDisclosureFileName}</a></label>
                      <input type="file" name="authorizationForDisclosureShow" id="authorizationForDisclosureShow"
                        disabled={isLoading}
                        key={store.authorizationForDisclosureFileName || " "}
                        onChange={(e) => {
                          setFieldsTouched('authorizationForDisclosureFile');
                          handleAuthorizationForDisclosureValueChange(e);
                        }}
                        className="d-none"
                        accept={AppSettings.validUploadFileFormats.toString()}>
                      </input>
                      <i className="bi bi-trash ms-1" onClick={handleRemoveAuthorizationForDisclosureFile}></i>
                    </div>
                  </ShowThen>
                </div>
              </div>
            </div>

          </form>
        </Card>
        <div className="row mt-4">
          <div className="col-12 col-md-6 order-1 order-md-0 mt-3 mt-md-0">
            <button type="button" className="btn bds-btn-primary"
              disabled={isLoading}
              onClick={() => NavigationHelper.gotoApplicationSummary(navigate, applicationRefId)}>Back</button>
            <IsAuthenticated>
              <button type="button"
                disabled={isLoading}
                className="btn bds-btn-primary ms-0 ms-md-3 mt-3 mt-md-0"
                onClick={() => NavigationHelper.gotoDashboard(navigate)}>Save for Later</button>
            </IsAuthenticated>
          </div>
          <div className="col-12 col-md-6 order-0 order-md-1 text-start text-md-end mt-3 mt-md-0">
            <button type="button" disabled={isLoading || (errors && errors.length > 0)}
              onClick={() => handleGoToNextStep()}
              className="btn bds-btn-primary">Next</button>
          </div>
        </div>
      </div>
    </>
  );
}
export default observer(ApplicantDocumentUpload);